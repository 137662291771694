import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private user: User;
  private registrationUrl = 'api/User/RegisterUser';
  private loginUrl = 'api/User/Login';
  private passwordResetUrl = 'api/User/resetUserPassword';
  private logoutUrl = 'api/Account/Logout'
  private accountChangeUrl = 'api/User/updateUser';
  private isSesssionTimeOut = 'api/user/getSessionTimeOut'
  headers = new HttpHeaders().set('Content-Type', 'application/json');


  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  registration(firstname:string,lastname:string,email:string , password:string,roles:string[],phone,hotel): Observable<any> {
    const body={
      FirstName:firstname,
      LastName:lastname,
      Email:email,
      Password:password,
      Roles:roles,
      PhoneNumber:phone,
      Hotel:hotel
    }
    return this.http
      .post<any>(`${environment.apiUrl}`+ this.registrationUrl, body)
      .pipe(
        catchError(error => {
           return throwError(() => error);
        }))
  }

  login(email:string , password:string) {
    const body={
      Email:email,
      Password:password
    }
    return this.http
      .post<any>(`${environment.apiUrl}`+this.loginUrl, body)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        },
        catchError(error => {
           return throwError(() => error);
        }))
      );
  }

  resetPassword(body) {
    return this.http
      .post<any>(`${environment.apiUrl}`+this.passwordResetUrl, body)
      .pipe(
        catchError(error => {
           return throwError(() => error);
        }))
  }

  accountChange(body) {
    return this.http
      .post<any>(`${environment.apiUrl}`+this.accountChangeUrl, body)
      .pipe(
        catchError(error => {
           return throwError(() => error);
        }))
  }

  logout() {
    // remove user from local storage to log user out
    return this.http
    .post<any>(`${environment.apiUrl}`+'/'+ this.logoutUrl, null, this.createHttpHeader())
    .pipe(
      map((user) => {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        return of({ success: false });
      },
      catchError(error => {
         return throwError(() => error);
      }))
    );
   
  }

  hasRole(role: Role) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return user && user.roles === role;
}
handleError(error: HttpErrorResponse) {
  let errorMessage = 'Unknown error!';
  if (error.error instanceof ErrorEvent) {
    // Client-side errors
    errorMessage = `Error: ${error.error.message}`;
  } else {
    // Server-side errors
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  window.alert(errorMessage);
  return throwError(errorMessage);
}
private createHttpHeader() {
  return {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  }
}
private createHttpHeaderencoded() {
  return {
    headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
  }
}

autoLogout() {
  return this.http
  .get<any>(`${environment.apiUrl}`+ this.isSesssionTimeOut)
  .pipe(
    catchError(error => {
      return error;
    })
  );
}

}
