import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faWindowMaximize } from '@fortawesome/free-regular-svg-icons';
import {
  faWindowMinimize,
  faExclamationTriangle,
  faExclamationCircle,
  faCaretRight,
  faTimesCircle,
  faCheckCircle,
  faClone,
  faThLarge,
  faWindowMaximize as fasWindowMaximize,
  faPrint,
  faServer,
  faShareAlt,
  faDoorOpen,
  faKey,
  faShapes,
  faUserCircle,
  faLayerGroup,
  faUserPlus,
  faUserAltSlash,
  faQuestionCircle,
  faSpinner,
  faPen,
  faSearch,
  faRedo,
  faCheck,
  faClock,
  faPencilAlt,
  faTrashAlt,
  faTimes,
  faBan,
  faBell,
  faThumbtack,
  faAngleRight,
  faAngleLeft,
  faPlusCircle,
  faMinusCircle,
  faUser,
  faCheckSquare,
  faCog,
  faChartBar,
  faLock,
  faProjectDiagram,
  faTools,
  faSignInAlt,
  faList,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronCircleLeft,
  faHome,
  faTachometerAlt,
  faArrowLeft,
  faInfoCircle,
  faSearchPlus,
  faAddressCard,
  faMale,
  faBinoculars,
  faBriefcase,
  faCommentDots,
  faFolderOpen,
  faDotCircle,
  faCircle,
  faFilter,
  faSort,
  faSyncAlt,
  faAngleDown,
  faFileAlt,
  faArrowDown,
  faArrowUp,
  faAngleUp,
  faEyeSlash,
  faPlus,
  faMinus,
  faCalendarAlt,
  faEye,
  faSortDown,
  faHistory


} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [FontAwesomeModule]
})
export class IconModule { 
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(
      faWindowMaximize,
      faWindowMinimize,
      faExclamationTriangle,
      faExclamationCircle,
      faCaretRight,
      faTimesCircle,
      faCheckCircle,
      faClone,
      faThLarge,
      fasWindowMaximize,
      faPrint,
      faServer,
      faShareAlt,
      faDoorOpen,
      faKey,
      faShapes,
      faUserCircle,
      faLayerGroup,
      faUserPlus,
      faUserAltSlash,
      faQuestionCircle,
      faSpinner,
      faPen,
      faSearch,
      faSearchPlus,
      faRedo,
      faCheck,
      faClock,
      faBan,
      faBell,
      faPencilAlt,
      faTrashAlt,
      faChevronRight,
      faChevronDown,
      faChevronUp,
      faChevronLeft,
      faChevronCircleLeft,
      faTimes,
      faThumbtack,
      faAngleRight,
      faAngleLeft,
      faPlusCircle,
      faMinusCircle,
      faUser,
      faCheckSquare,
      faCog,
      faChartBar,
      faLock,
      faProjectDiagram,
      faTools,
      faSignInAlt,
      faList,
      faInfoCircle,
      faHome,
      faTachometerAlt,
      faArrowLeft,
      faAddressCard,
      faMale,
      faBinoculars,
      faBriefcase,
      faCommentDots,
      faFolderOpen,
      faDotCircle,
      faCircle,
      faFilter,
      faSort,
      faSyncAlt,
      faAngleDown,
      faFileAlt,
      faArrowDown,
      faArrowUp,
      faAngleUp,
      faEyeSlash,
      faMinus,
      faPlus,
      faEye,
      faSortDown,
      faHistory,
      faCalendarAlt
    );
  }
}
