import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { Staff } from './staff.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/Utils';
import { Guest } from './guest.model';

@Injectable()
export class StaffService {
  private readonly API_URL = 'assets/data/staff.json';
  private readonly getDefaultRolesURL = 'api/Staff/getDefaultRoles';
  private readonly getStaffUrl = 'api/Staff/getStaffDetails';
  private readonly getGuestUrl = 'api/Staff/getGuestDetails';
  private readonly updateStaffUrl = 'api/Staff/updateStaff';
  private readonly createStaffUrl = 'api/Staff/createStaff';
  private readonly deleteStaffUrl = 'api/Staff/deleteStaff';
  private readonly getUserDetailsByEmailUrl = 'api/Staff/getUserDetailsByEmail';

  isTblLoading = true;
  dataChange: BehaviorSubject<Staff[]> = new BehaviorSubject<Staff[]>([]);
  userEdit: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) { }
  get data(): Staff[] {
    return this.userEdit.value;
  }

  setSelectedUserForEdit(data) {
    this.userEdit = new BehaviorSubject<any>([])
    this.userEdit = data;
  }

  getSelectedUserForEdit() {
    return this.userEdit;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */
  getAllStaffs()  {
    return this.httpClient.get<Staff[]>(`${environment.apiUrl}`+ this.getStaffUrl)
    .pipe(
      catchError(error => {
         return throwError(() => error);
      })
    )
  }

  getAllGuests()  {
    return this.httpClient.get<Guest[]>(`${environment.apiUrl}`+ this.getGuestUrl)
    .pipe(
      catchError(error => {
         return throwError(() => error);
      })
    )
  }

  getDefaultRoles()  {
    return this.httpClient.get<any[]>(`${environment.apiUrl}`+ this.getDefaultRolesURL)
    .pipe(
      catchError(error => {
         return throwError(() => error);
      })
    )
  }

  addStaff(data): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}`+ this.createStaffUrl, data)
      .pipe(
        catchError(error => {
           return throwError(() => error);
        }))
  }
  getUserDetailsByEmail(data): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}`+ this.getUserDetailsByEmailUrl, data)
      .pipe(
        catchError(error => {
           return throwError(() => error);
        }))
  }

  updateStaff(data): Observable<any> {
    // this.dialogData = staff;
    // const body={
    //   id:staff.id,
    //   firstname:staff.firstname,
    //   lastname:staff.lastname,
    //   department:staff.department,
    //   mobile:staff.mobile,
    //   email:staff.email,
    //   portallogin:staff.portallogin,
    //   address:staff.address
    // }
    return this.httpClient.post<any>(`${environment.apiUrl}`+ this.updateStaffUrl, data)
      .pipe(
        catchError(error => {
           return throwError(() => error);
        }))
  }
  
  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;   
}
  
  deleteStaff(id: number): Observable<any> {
    const body={
      id:id
    }
    return this.httpClient.post<any>(`${environment.apiUrl}`+ this.deleteStaffUrl, body)
      .pipe(
        catchError(error => {
           return throwError(() => error);
        }))
  }
}
