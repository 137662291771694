<div class="k-overlay" *ngIf="show"></div>
<kendo-dialog *ngIf="show" (close)="btnClick('cancel')" class="conformation-popup" [minWidth]="minWidth" [width]="width"
              class="popup-double"
              title="{{title}}"
              (close)="btnClick('cancel')"
              [ngClass]="{'information': type=='information', 'warning': type=='warning', 'success': type=='success', 'error': type=='error', 'failure': type=='failure', 'confirm': type=='confirm'}">
  <div class="main-text">
    <fa-icon *ngIf="type === 'information'" class="image image__information" [icon]="['fas', 'info-circle']"></fa-icon>
    <fa-icon *ngIf="type === 'warning'" class="image image__confirm" [icon]="['fas', 'exclamation-triangle']"></fa-icon>
    <fa-icon *ngIf="type === 'success'" class="image image__success" [icon]="['fas', 'check-circle']"></fa-icon>
    <fa-icon *ngIf="type === 'error'" class="image image__error" [icon]="['fas', 'times-circle']"></fa-icon>
    <fa-icon *ngIf="type === 'failure'" class="image image__failure" [icon]="['fas', 'times-circle']"></fa-icon>
    <fa-icon *ngIf="type === 'confirm'" class="image image__confirm" [icon]="['fas', 'question-circle']"></fa-icon>
    {{mainText}}
  </div>

  <div class="col-sm-12 saveButton" *ngIf="msgYesNo">
    <button  type="button" class="btn btn-outline-secondary" (click)="btnClick('cancel')" >{{btn2Text}}</button>
    <button  type="button" class="btn btn-outline-secondary" (click)="btnClick('accept')">{{btn1Text}}</button>
	</div>
  <div class="col-sm-12 saveButton" *ngIf="msgOk">
    <button type="button" class="btn btn-outline-secondary" id="qa-btn3" (click)="btnClick('ok')">OK</button>
  </div>
</kendo-dialog>
