import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, Observable, of, takeUntil } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { SessionActivityService } from '../../common/services/session-activity.service';
import { Utils } from 'src/app/Utils';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private router: Router, private sessionActivityService: SessionActivityService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    let currentUser = this.authenticationService.currentUserValue;
    const localData = JSON.parse(localStorage.getItem('currentUser'));
    if( this.isActive(localData) || request.url.includes('/User/Login')) {
        if (currentUser && currentUser.token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${currentUser.token}`,
            },
          });
        }
        return next.handle(request).pipe(
          catchError(
            (err, caught) => {
              if (err.status === 401){
                this.handleAuthError();
                return of(err);
              }
              throw err;
            }
          )
        );
  } else {
    this.handleAuthError();
  }
  }
  private handleAuthError() {
    localStorage.clear();
    this.router.navigate(['/authentication/signin']);
  }
  isActive(localData) {
    if(Utils.isNullOrUndefinedOrEmptyOrWithWhiteSpaceString(localData)) {
      return false;
    } else if(this.sessionActivityService.getIsSessionActive()) {
      this.sessionActivityService.clearSessiontimerCount();
      this.sessionActivityService.setLastActivityTime(Date.now());
      this.sessionActivityService.startTimestamp();
    return true;
    } else {
      return false;
    }
  }

}
