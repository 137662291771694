import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd, ActivationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, Observable, Subscription } from 'rxjs';
import { SessionActivityService } from './common/services/session-activity.service';
import { TimeoutPopupComponent } from './common/timeout-popup/timeout-popup.component';
import { AuthService } from './core/service/auth.service';
import { Utils } from './Utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnDestroy {
  @ViewChild(TimeoutPopupComponent) timeoutPopupComponent: TimeoutPopupComponent;
  currentUrl: string;
  showLogOutPopup: boolean;
  isSessionActive: number;
  sessiontimerCount: Subscription;
  sessionTimoutPopup: boolean;

  constructor(public _router: Router, private spinner: NgxSpinnerService,
    private authService: AuthService,
    private router: Router,
    private sessionActivityService: SessionActivityService
    ) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });
    
  }
  
  getPopupStatus() {
    return this.sessionActivityService.getLogoutPopupStatus();
  }

  autoLogout() {
    this.authService.autoLogout().subscribe(
      (res: any) => {
        if(res.success === 1) {
          setTimeout(() => {
            this.clearStorage();
            this.sessionActivityService.setLogoutPopupStatus(true);
          }, res.data);          
        } else {
          this.clearStorage();
          this.sessionActivityService.setLogoutPopupStatus(true);
        }
      }
    )
  }

  clearStorage() {
    this.sessionActivityService.setIsSessionActive(false);
    localStorage.clear();
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/authentication/signin']);
    this.sessionTimoutPopup = false;
  }

  ngOnDestroy() {
    this.sessionActivityService.clearSessiontimerCount();
    this.timeoutPopupComponent.checkTimestampStatus();
  }

  handlerOnClickBtnPopupError() {
    this.sessionActivityService.setLogoutPopupStatus(false);

    localStorage.clear();
    this.router.navigate(['/authentication/signin']);
  }

  handlerSessionTimoutPopup(status) {
    this.sessionTimoutPopup = false;
    if (status === 'accept') {
      this.timeoutPopupComponent.checkTimestampStatus();
      this.sessionActivityService.setLastActivityTime(Date.now());
    } else {
      this.timeoutPopupComponent.checkTimestampStatus();
      this.logout();
    }
  }
}
