import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StaffService } from '../../staff/all-staff/staff.service';
import { MatTableDataSource } from '@angular/material/table';
import { Staff } from 'src/app/staff/all-staff/staff.model';
import { DataSource, SelectionModel } from '@angular/cdk/collections';


import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AuthZService } from 'src/app/core/service/authz.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Utils } from 'src/app/Utils';
export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.scss']
})
export class AdvanceSearchComponent implements OnInit {
  existingUser: any;
  displayedColumns = [
    'actions',
    'fullname',
    'department',
    'mobile',
    'email',

  ];
  exampleDatabase: StaffService | null;
  dataSourceStaff: MatTableDataSource<Staff>;
  staffList: Staff[] = [];
  selection = new SelectionModel<Staff>(true, []);
  id: number;
  staff: Staff | null;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  isDataExist: boolean;
  checkedIDs: any[] = [];
  constructor(private fb: FormBuilder,  public dialogRef: MatDialogRef<AdvanceSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public result: DialogData,
    
    public httpClient: HttpClient,
    public dialog: MatDialog,
    public staffService: StaffService,
    private snackBar: MatSnackBar,
    private router: Router,) { }

  ngOnInit(): void {
    this.existingUser = this.fb.group({
      name: [''],
      number: [''],
    })
    this.loadData();
  }
  finduser(){
    this.isDataExist = true;
    console.log('Form Value', this.existingUser.value);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  checkUserFilledData(){
    const data = this.existingUser.value;
    if (!Utils.isNullOrUndefinedOrEmptyOrWithWhiteSpaceString(data.name) || !Utils.isNullOrUndefinedOrEmptyOrWithWhiteSpaceString(data.number))
      return false;
    else
      return true;
  }

  // finf
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceStaff.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSourceStaff.data.forEach((row) =>{
        this.selection.clear();
        this.selection.select(row)

      }
      );
  }
  removeSelectedRows() {
    const totalSelect = this.selection.selected.length;
    this.selection.selected.forEach((item) => {
      const index: number = this.dataSourceStaff.data.findIndex(
        (d) => d === item
      );
      // console.log(this.dataSource.renderedData.findIndex((d) => d === item));
      this.exampleDatabase.dataChange.value.splice(index, 1);
      this.selection = new SelectionModel<Staff>(true, []);
    });
    this.showNotification(
      'snackbar-danger',
      totalSelect + ' Record Delete Successfully...!!!',
      'bottom',
      'center'
    );
  }

  changeSelection(event: any, row) {
    this.checkedIDs = []
    if (event.target.checked) {
     this.checkedIDs.push(row.id);
       this.result = row;
      }
    }


  public loadData() {
    this.staffService.getAllStaffs().subscribe(
      (data: any) => {
        if (data.message === "Invalid Token...") {
          this.router.navigate(['/authentication/signin']);
        }

        this.staffService.dataChange.next(data.data);
        this.staffList = [];

        data.data.forEach(element => {
          this.staffList.push({
            'id': element.id,
            'img': element.img,
            'fullname': element.first_name + ' ' + element.last_name,
            'department': element.department,
            'email': element.email,
            'portallogin': element.portallogin,
            'address': element.address,
            'usertype': element.user_type,
            'mobile': element.mobile,
            'firstname': element.first_name,
            'lastname': element.last_name
          })
        });
        this.dataSourceStaff = new MatTableDataSource<Staff>(this.staffList);
        this.dataSourceStaff.paginator = this.paginator;
        this.dataSourceStaff.sort = this.sort;
        fromEvent(this.filter.nativeElement, 'keyup')
          // .debounceTime(150)
          // .distinctUntilChanged()
          .subscribe(() => {
            if (!this.dataSourceStaff) {
              return;
            }
            this.dataSourceStaff.filter = this.filter.nativeElement.value;
          });
      })
  }

  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, '', {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  showTitleErorIcon(titleText, message) {
    Swal.fire({
      icon: 'error',
      title: titleText,
      text: message
    });
  }

  // context menu
  onContextMenu(event: MouseEvent, item: Staff) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item: item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }
}
export class ExampleDataSource extends DataSource<Staff> {
  _filterChange = new BehaviorSubject('');
  get filter(): string {
    return this._filterChange.value;
  }
  set filter(filter: string) {
    this._filterChange.next(filter);
  }
  filteredData: Staff[] = [];
  renderedData: Staff[] = [];
  constructor(
    public _exampleDatabase: StaffService,
    public _paginator: MatPaginator,
    public _sort: MatSort
  ) {
    super();
    // Reset to the first page when the user changes the filter.
    this._filterChange.subscribe(() => (this._paginator.pageIndex = 0));
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Staff[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this._exampleDatabase.dataChange,
      this._sort.sortChange,
      this._filterChange,
      this._paginator.page,
    ];
    //this._exampleDatabase.getAllStaffs();
    return merge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        this.filteredData = this._exampleDatabase.data
          .slice()
          .filter((staff: Staff) => {
            const searchStr = (
              staff.fullname +
              staff.department +
              staff.email +
              staff.mobile +
              staff.portallogin +
              staff.usertype
            ).toLowerCase();
            return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
          });
        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());
        // Grab the page's slice of the filtered sorted data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        this.renderedData = sortedData.splice(
          startIndex,
          this._paginator.pageSize
        );
        return this.renderedData;
      })
    );
  }
  disconnect() { }
  /** Returns a sorted copy of the database data. */
  sortData(data: Staff[]): Staff[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }
    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';
      switch (this._sort.active) {
        case 'id':
          [propertyA, propertyB] = [a.id, b.id];
          break;
        case 'fullname':
          [propertyA, propertyB] = [a.fullname, b.fullname];
          break;
        case 'email':
          [propertyA, propertyB] = [a.email, b.email];
          break;
        case 'mobile':
          [propertyA, propertyB] = [a.mobile, b.mobile];
          break;
      }
      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
      return (
        (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1)
      );
    });
  }
}
