import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, CanLoad, Route
} from '@angular/router';
import { Observable } from 'rxjs';
import { Role } from '../models/role';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}
  autorized: Boolean;
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if (this.authService.currentUserValue) {
    //   return true;
    // }
    // this.router.navigate(['/authentication/signin']);
    // return false;

    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      this.autorized = false;

      if (
        route.data.role &&
        currentUser.roles.find(x => x.roletype === route.data.role[0])
      ) {
        // Current user has role that is the (first and only) role on route
        this.autorized = true;
      }
      if (!route.data.role) {
        // there is no role on route
        this.autorized = true;
      }

      if (this.autorized) {
        // authorised so return true
        return true;
      } else {
        // role not authorised so redirect to home page
        this.router.navigate(["/"]);
        return false;
      }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
   
    const roles = route.data && route.data.roles as Role[];
    if (roles && !roles.some(r => this.authService.hasRole(r))) {
        return false;
    }

    return true;
}

canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  const currentUser = this.authService.currentUserValue;
    if (currentUser) {
        // logged in so return true
        return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
}
}
