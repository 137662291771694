import * as clone from 'clone';
import * as deepEqual from 'deep-equal';
import Swal from 'sweetalert2';

export class Utils {
    // conforms to date/time format in calendar.options.ts and ReportWriter.java
    static readonly DATE_FORMAT = 'MM/dd/yyyy';
    static readonly TIME_FORMAT = 'hh:mm:ss a';

    static isNullOrUndefined(value: any): boolean {
        return value === null || value === undefined;
    }

    static isNullOrUndefinedOrEmptyString(value: string) {
        return this.isNullOrUndefined(value) || value === '';
    }

    static isNullOrUndefinedOrEmptyOrWithWhiteSpaceString(value: string) {
        return this.isNullOrUndefined(value) || value === '' || this.checkWhiteSpace(value);
    }

    static checkWhiteSpace(value: string) {
        return (!value || /^\s*$/.test(value));
    }

    static toIsoString(date: Date): string {
        return date.toISOString().split('.')[0] + 'Z';
    }

    static toDateLocalized(value: string | number | Date, locale: string, timezone?: string): string {
        return '';
    }

    static toTimeLocalized(value: string | number | Date, locale: string, timezone?: string): string {
        return '';
    }

    static toDateTimeLocalized(value: string | number | Date, locale: string, timezone?: string): string {
        return value.toString();
    }

    static convertToLocalDate(dateString): Date {
        const dat = new Date();
        const dateTimeArr = dateString.toString().split('T');
        if (dateTimeArr.length > 0) {
            try {
                const dateStr = dateTimeArr[0];
                const timeString = dateTimeArr[1];
                const dateArr = dateStr.split('-');
                const year = dateArr[0];
                const month = dateArr[1];
                const day = dateArr[2];
                const arr = timeString.split(':');
                const hours = arr[0];
                const minutes = arr[1];
                const seconds = arr[2].split('.')[0];
                dat.setUTCFullYear(+year, parseInt(month, 10) - 1, +day);
                dat.setUTCHours(+hours);
                dat.setUTCMinutes(+minutes);
                dat.setUTCSeconds(+seconds);
                dat.toLocaleString();
                return dat;
            } catch (err) {
                throw err;
            }
        }
        return new Date(dateString);
    }


    static generateIdFromName(name: string): string {
        if (this.isNullOrUndefined(name) || name === '') {
            return '';
        }
        const date = new Date();
        return name.trim() + '_' + this.toIsoString(date);
    }

    static clone<T>(object: T): T {
        return clone(object);
    }

    static deepEqual(obj1: any, obj2: any): boolean {
        return deepEqual(obj1, obj2);
    }

    static getSubField(rowData: any, field: string): string {
        // get field from root
        let value: string = rowData[field];
        if (value) {
            return value;
        }

        // get field from sub objects
        value = '';
        for (const property in rowData) {
            if (rowData.hasOwnProperty(property)) {
                if (rowData[property] instanceof Array) {
                    for (const p of rowData[property]) {
                        if (value.length) {
                            value += ', ';
                        }
                        value += this.getSubField(p, field);
                    }
                } else if (rowData[property] instanceof Object) {
                    value = this.getSubField(rowData[property], field);
                }

                if (value) {
                    return value;
                }
            }
        }

        return value;
    }

    static getUserString(data: any, fields: string[]): string {
        return '';
    }

    static trimString(string: string): string {
        if (!this.isNullOrUndefinedOrEmptyString(string)) {
            return string.trim();
        } else {
            return string;
        }
    }

    static showPopupWithIcon(type, titleText, message) {
        Swal.fire({
          icon: type,
          title: titleText,
          text: message
        });
    }

    static showSuccessMessage(message) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
      };

    static transformToTitleCase(data){
        return data.replace(
            /\w\S*/g,
            function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

}
