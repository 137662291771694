import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent implements OnInit {
  @Input() title: string;
  @Input() btn1Text: string;
  @Input() btn2Text: string;
  @Input() minWidth: number;
  @Input() width: number;
  @Input() mainText: string;
  @Input() show: boolean;
  @Input() type: string;
  @Input() msgOk: boolean;
  @Input() msgYesNo: boolean;
  @Output() onClickBtnPopupDouble = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.minWidth = 250;
    this.width = 450;
  }

  public btnClick(status: string) {
    this.onClickBtnPopupDouble.emit(status);
  }


}
