import { Injectable } from '@angular/core';
import { interval, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionActivityService {
  lastActivityTime: number;
  logoutPopupStatus: boolean;
  isSessionActive: boolean = true;
  fiveMinuteTimestamp = 1200; //seconds
  sessiontimerCount: Subscription;

  constructor() { }

  getLastActivityTime() {
    return this.lastActivityTime;
  }

  setLastActivityTime(lastActivityTime) {
    this.lastActivityTime = lastActivityTime;
  }
  getIsSessionActive() {
    return this.isSessionActive;
  }

  setIsSessionActive(session) {
    this.isSessionActive = session;
  }

  getLogoutPopupStatus() {
    return this.logoutPopupStatus;
  }

  setLogoutPopupStatus(satus) {
    this.logoutPopupStatus = satus;
  }

  startTimestamp() {
    this.clearSessiontimerCount();
    const sessionTimeStamp = this.fiveMinuteTimestamp;
    const sessiontimer = interval(1000);
    this.sessiontimerCount = sessiontimer.subscribe((sec) => {
      if (sec === sessionTimeStamp) {
        this.isActive();
      }
    });
  }

  clearSessiontimerCount() {
    if (this.sessiontimerCount)
      this.sessiontimerCount.unsubscribe();
  }

  isActive() {
    const timestamp = this.fiveMinuteTimestamp;
    const active = this.getLastActivityTime()
    const lastActivityTime = active !== undefined ? active : 0;
    if (Date.now() - lastActivityTime > timestamp) {
      this.clearStorage();
      this.setLogoutPopupStatus(true);
    } else {
      this.setIsSessionActive(true);
      this.setLastActivityTime(Date.now());
    }
  }

  clearStorage() {
    this.setIsSessionActive(false);
    this.sessiontimerCount.unsubscribe();
    localStorage.clear();
    localStorage.clear();
  }
}
