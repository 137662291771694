<app-page-loader></app-page-loader>
<router-outlet></router-outlet>
<app-notification-popup 
    [title]="'Warning'"
    [btn1Text]="'Yes'"
    [btn2Text]="'No'"
    [mainText]="'Your session has expired. Click OK to login and continue'"
    [show]="getPopupStatus()"
    [type]="'warning'"
    [msgOk]="true"
    [msgYesNo]="false"
    (onClickBtnPopupDouble)="handlerOnClickBtnPopupError()">
</app-notification-popup>

<app-timeout-popup 
    [title]="'Warning'"
    [btn1Text]="'Ok'"
    [btn2Text]="'Cancel'"
    [mainText]="'Session get timeout. Click on OK Continue'"
    [show]="sessionTimoutPopup"
    [type]="'warning'"
    [msgOk]="false"
    [msgYesNo]="true"
    (logout)="logout()"
    (onClickBtnPopupDouble)="handlerSessionTimoutPopup($event)">
</app-timeout-popup>