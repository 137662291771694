<h1 mat-dialog-title>Advance Search</h1>
<div mat-dialog-content>
  <!-- <p>What's your favorite animal?</p>
  <mat-form-field appearance="fill">
    <mat-label>Favorite Animal</mat-label>
    <input matInput [(ngModel)]="data.animal">
  </mat-form-field> -->
  <form  class="m-1" [formGroup]="existingUser" (ngSubmit)="finduser()">

  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
      <mat-form-field class="example-full-width mb-3" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
      <mat-form-field class="example-full-width mb-3" appearance="outline">
        <mat-label>Number</mat-label>
        <input matInput formControlName="number">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
      <button class="msr-3" [disabled]="checkUserFilledData()" mat-raised-button
        color="primary">Filter</button>
    </div>
  </div>
  </form>

  <div class="table-responsive mt-2" *ngIf="isDataExist">
    <div class="materialTableHeader">
      <div class="row">
        <div class="col-8">
          <ul class="header-buttons-left ml-0">
            <li class="dropdown">
              <h2>
                <strong>User List</strong></h2>
            </li>
            <li class="dropdown m-l-20" style="display: none;">
              <label for="search-input"><i class="material-icons search-icon">search</i></label>
              <input placeholder="Search" type="text" #filter class="browser-default search-field"
                aria-label="Search box">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <mat-table #table [dataSource]="dataSourceStaff" matSort class="mat-cell">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
          <mat-cell *matCellDef="let row" matTooltip="{{row.id}}">{{row.id}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef [ngClass]="'tbl-col-width-per-12'">
                <!-- <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [ngClass]="'tbl-checkbox'">
                </mat-checkbox>-->
              </mat-header-cell> 
              <mat-cell *matCellDef="let row" [ngClass]="'tbl-col-width-per-12'">
                <input type="checkbox"
                  (click)="changeSelection($event, row)" [checked]="checkedIDs.includes(row.id)"
                  [ngClass]="'tbl-checkbox'"/>
                
              </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fullname">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let row" matTooltip="{{row.fullname}}" (contextmenu)="onContextMenu($event, row)">
            {{row.fullname}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="department">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Department</mat-header-cell>
          <mat-cell *matCellDef="let row" matTooltip="{{row.department}}" (contextmenu)="onContextMenu($event, row)">
            {{row.department}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="mobile">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</mat-header-cell>
          <mat-cell *matCellDef="let row" matTooltip="{{row.mobile}}" (contextmenu)="onContextMenu($event, row)">
            {{row.mobile}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
          <mat-cell *matCellDef="let row" matTooltip="{{row.email}}" (contextmenu)="onContextMenu($event, row)">
            {{row.email}}</mat-cell>
        </ng-container>

        
        <!-- actions -->

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" 
          [style.cursor]="'pointer'" matRipple>
        </mat-row>
      </mat-table>
    <!-- Loading spinner -->
    <!-- <div *ngIf="exampleDatabase.isTblLoading" class="tbl-spinner">
      <mat-progress-spinner color="primary" [diameter]="40" mode="indeterminate">
      </mat-progress-spinner>
    </div> -->
    
    <!-- <div class="no-results" [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'">
      No results
    </div>
    <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator> -->
</div>
</div>






<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="result" cdkFocusInitial>Ok</button>
</div>