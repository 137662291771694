import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Utils } from 'src/app/Utils';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
@Injectable({
  providedIn: 'root'
})
export class AuthZService {

  private userActivityUrl = 'api/User/logUserActivity';
  private userGetActivityUrl = 'api/User/getUserActivity';
  

  constructor(private http: HttpClient) { }

  signOut() {
    window.localStorage.clear();
  }

  validatePermission(key) {
    const loggedInUser = JSON.parse(window.localStorage.getItem('auth-user'));
    if (!Utils.isNullOrUndefinedOrEmptyOrWithWhiteSpaceString(loggedInUser)) {
      if (loggedInUser[key] === 1)
        return true;
      else
        return false;
    } else {
      return false;
    }
      
  }

  public saveToken(token: string) {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user) {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  

  getUserActivity(email:string) {
    if (email.length === 0 )
    {
      const loggedInUser = JSON.parse(window.localStorage.getItem('auth-user'));
      email = loggedInUser.email;
    }    
    const data={
      Email:email      
    }
    return this.http.post(`${environment.apiUrl}`+ this.userGetActivityUrl, data)
    .pipe(
      catchError(error => {
         return throwError(() => error);
      })
    )
  }

  logUserActivity(email:string,  type:string , info:string, status:string) {
    if (email.length === 0 )
    {
      const loggedInUser = JSON.parse(window.localStorage.getItem('auth-user'));
      email = loggedInUser.email;
    }    
    const data={
      Email:email,
      Type:type,
      Info:info,
      Status: status
    }
    return this.http.post(`${environment.apiUrl}`+ this.userActivityUrl, data)
    .pipe(
      catchError(error => {
         return throwError(() => error);
      })
    )
  }

  checkIsSessionValid() {
    const loggedInUser = JSON.parse(window.localStorage.getItem('auth-user'));
    if (!Utils.isNullOrUndefinedOrEmptyOrWithWhiteSpaceString(loggedInUser))
      return true;
    else 
    return false;
  }
}
