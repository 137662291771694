import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
@Component({
  selector: 'app-timeout-popup',
  templateUrl: './timeout-popup.component.html',
  styleUrls: ['./timeout-popup.component.scss']

})
export class TimeoutPopupComponent implements OnInit, OnDestroy {
  progressbarValue: number;
  @Input() title: string;
  @Input() btn1Text: string;
  @Input() btn2Text: string;
  @Input() minWidth: number;
  @Input() width: number;
  @Input() mainText: string;
  @Input() show: boolean;
  @Input() type: string;
  @Input() msgOk: boolean;
  @Input() msgYesNo: boolean;
  @Output() onClickBtnPopupDouble = new EventEmitter();
  @Output() logout = new EventEmitter();

  isTimestampCompleted: boolean = false;
  checkTimeInterval: any;
  isStatusChange: boolean = false;
  
  constructor( private router: Router,) {
  }
  
  ngOnInit() {
    this.minWidth = 250;
    this.width = 450;
  }

  public btnClick(status: string) {
    if (status !== 'accept') {
      this.isStatusChange = true;
    }
    this.onClickBtnPopupDouble.emit(status);
  }
  startTimer() {
    const time = 10;
    this.checkStatus();
    const timer$ = interval(1000);
    this.checkTimeInterval = timer$.subscribe((sec) => {
    this.progressbarValue = 0 + sec * 100 / time;    
    });
  }


  checkStatus() {
    setTimeout(() => {
      if (this.isStatusChange) {
        this.isStatusChange = false;
        this.timeOut();
      }          
    }, 10000); 
  }

  checkTimestampStatus() {
    this.checkTimeInterval.unsubscribe();
  }

public timeOut() {
    this.logout.emit();
  }
  ngOnDestroy(){
    this.checkTimestampStatus();
  }
}
