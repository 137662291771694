<div class="k-overlay" *ngIf="show"></div>
<kendo-dialog *ngIf="show" (close)="btnClick('cancel')" class="conformation-popup" [minWidth]="minWidth" [width]="width"
              class="popup-double"
              title="{{title}}"
              (close)="btnClick('cancel')"
              [ngClass]="{'information': type=='information', 'warning': type=='warning', 'success': type=='success', 'error': type=='error', 'failure': type=='failure', 'confirm': type=='confirm'}">
  <div class="main-text">
    <fa-icon *ngIf="type === 'warning'" class="image image__confirm" [icon]="['fas', 'exclamation-triangle']"></fa-icon>
    {{mainText}}
  </div>
  <div class="basic-container">
	<mat-progress-bar mode="determinate" [value]="progressbarValue"></mat-progress-bar>
</div>


  <div class="col-sm-12 saveButton" *ngIf="msgYesNo">
    <button  type="button" class="btn btn-outline-secondary btn-basic" (click)="btnClick('cancel')" >{{btn2Text}}</button>
    <button  type="button" class="btn btn-outline-secondary btn-basic" (click)="btnClick('accept')">{{btn1Text}}</button>
	</div>
  <div class="col-sm-12 saveButton" *ngIf="msgOk">
    <button type="button" class="btn btn-outline-secondary btn-basic" id="qa-btn3" (click)="btnClick('ok')">OK</button>
  </div>
</kendo-dialog>